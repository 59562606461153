export const followMastodonInit = () => {
    const form = document.getElementById('followModal')
    const mastodonInstance = document.getElementById('mastodonInstance') as HTMLInputElement
    const followSource = document.getElementById('followSource') as HTMLSelectElement
    const mastodonInstanceGroup = document.querySelector('.mastodon-instance-group') as HTMLElement

    if (!form || !mastodonInstance || !followSource) return

    form.addEventListener('submit', function (event) {
        event.preventDefault() // Prevent form submission

        const instanceUrl =
            !mastodonInstance.value || mastodonInstance.value == ''
                ? 'https://elk.zone/'
                : `${mastodonInstance.value}/authorize_interaction?uri=`
        const action = followSource.value !== '/feed.rss' ? `${instanceUrl}${followSource.value}` : '/feed.rss'

        window.open(action, '_blank')
    })

    followSource.addEventListener('change', function (event) {
        if (followSource.value !== '/feed.rss') {
            mastodonInstanceGroup.classList.add('enabled')
            mastodonInstance.disabled = false
            mastodonInstance.focus()
        } else {
            mastodonInstanceGroup.classList.remove('enabled')
            mastodonInstance.disabled = true
        }
    })
}
