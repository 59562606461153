export const initTracking = () => {
    if (typeof umami === 'undefined') {
        return
    }

    trackCommentListInViewport()

    // umami.track('signup-button', { name: 'newsletter', id: 123 });
}

const trackCommentListInViewport = () => {
    const commentList = document.querySelector('#comments')

    if (!commentList) {
        return
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                umami.track('comments-inview')
            }
        })
    })

    observer.observe(commentList)
}
