export const videoEmbed = () => {
    const videos = document.querySelectorAll('.video')

    videos.forEach((video) => {
        const width = video.clientWidth
        const height = width * 0.5625

        const iframe: HTMLElement = video.querySelector('iframe')
        iframe.style.width = width + 'px'
        iframe.style.height = height + 'px'
    })
}
