import { CountUp } from 'countup.js'

export const isDesktopClient = (): boolean => {
    return window.innerWidth >= 1200
}

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';path=/;' + expires
}

export const getCookie = (name) => {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length == 2) return parts.pop().split(';').shift()
}

export const animateCounter = (startingNumber: number, endingNumber: number | null): void => {
    const countElement = document.querySelectorAll('.count')

    countElement.forEach((counter: HTMLElement) => {
        if (endingNumber === null) {
            endingNumber = parseInt(counter.textContent)
        }

        const separateBy = !counter.dataset.separator ? '.' : counter.dataset.separator

        var countUp = new CountUp(counter, endingNumber, {
            startVal: startingNumber,
            useEasing: false,
            separator: separateBy,
        })
        countUp.start()
    })
}

export const hasThreeCardsInListing = () => {
    const listings: NodeListOf<Element> = document.querySelectorAll('.listing')

    if (!listings) {
        return false
    }

    listings.forEach((listing) => {
        if (listing.querySelectorAll('.card').length > 2) {
            listing.classList.add('has-flexible-cards')
        }
    })
}

String.prototype.replaceAll = function (strReplace, replaceWith) {
    // See http://stackoverflow.com/a/3561711/556609
    const esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    const reg = new RegExp(esc, 'gmi')
    return this.replace(reg, replaceWith)
}

String.prototype.replaceAllWithPlaceholder = function (strReplace, replaceWith, placeholder) {
    const esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    const regex = new RegExp(esc, 'gmi')
    const matches = this.match(regex)
    let targetString = this

    matches.forEach((match) => {
        targetString = targetString.replace(match, replaceWith.replace(placeholder, match))
    })

    return targetString
}
