import tippy from 'tippy.js'

type LinkPreview = {
    id: string
    url: string
    title: string | null
    description: string | null
    image: string | null
}

declare let previewJson: LinkPreview[]

export const initTippy = (): void => {
    const startTippy = async (): Promise<void> => {
        await document.querySelectorAll('.page-text a:not(.no-tooltip)').forEach((element) => {
            const url = element.getAttribute('href')
            const info = getLinkInfo(url)

            if (!info || !info.title) {
                return
            }

            const escapedTitle = info.title.replace(/"/g, '&quot;')
            element.setAttribute('data-tippy-content', escapedTitle)
        })

        tippy('[data-tippy-content]', {
            content(reference) {
                const url = reference.getAttribute('href')
                const content = reference.getAttribute('data-tippy-content')
                const tooltip = `<strong class="tippy-strong">HI${content}</strong>${url}`

                return tooltip
            },
            allowHTML: true,
            theme: 'light-border',
        })

        tippy('.book-container .book', {
            content(reference) {
                const ratings: string[] = []
                const book = reference as HTMLDivElement

                const ratingString = book.dataset.rating || '0'
                const rating = parseInt(ratingString)
                const title = book.dataset.title
                const author = book.dataset.author

                if (rating > -1) {
                    for (let index = 1; index < 6; index++) {
                        if (rating >= index) {
                            ratings.push(
                                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 rating-star">
                                <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                            </svg>`
                            )
                        } else {
                            ratings.push(
                                `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 rating-star">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>`
                            )
                        }
                    }
                }

                return `${author}<br><strong>${title}</strong><br>${ratings.join('')}`
            },
            allowHTML: true,
            theme: 'light-border',
        })
    }

    const getLinkInfo = (url): LinkPreview | null => {
        // const cleanedUrl = url.indexOf('/') === 0 ? `${window.location.protocol}//${window.location.host}${url}` : url

        const preview = previewJson.find((entry) => {
            return entry.id == url
        })

        if (!preview) {
            return null
        }

        return {
            title: preview.title,
            url: url,
            id: preview.id,
            description: !preview.description ? '' : preview.description,
            image: !preview.image ? '' : `<img src="${preview.image}" alt="">`,
        }
    }

    startTippy()
}
