import { initTippy } from './tippy'
import { getCurrentWeatherCondition } from './weather'
import { videoEmbed } from './media'
import { copyToClipboard } from './clipboard'
import { followMastodonInit } from './mastodon'
import { initNavigation, initNavAnimation } from './navigation'
import { enableScrollSpy } from './scrollspy'
import { initTracking } from './tracking'
import { quickReply } from './quickreply'
import { getCookie } from './helper'

getCurrentWeatherCondition()
videoEmbed()
copyToClipboard()
followMastodonInit()
initNavigation()
initTippy()
quickReply()

const footnotes = document.querySelectorAll('.footnote-backref')
if (footnotes) {
    footnotes.forEach((footnote) => {
        footnote.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" /></svg>`
    })
}

const modalTriggers = document.querySelectorAll('.modalTrigger')
modalTriggers.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
        const modalId = trigger.getAttribute('data-modal-id')

        if (!modalId) {
            return
        }

        event.preventDefault()

        const modal = document.getElementById(modalId) as HTMLDialogElement

        if (!modal) {
            return
        }

        const closeButtons = modal.querySelectorAll('.close')

        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', () => {
                modal.close()
            })
        })

        modal.showModal()
    })
})

const contributionYearSelect = document.querySelector('select.contribution-year')

if (contributionYearSelect) {
    contributionYearSelect.addEventListener('change', (event) => {
        const year = (event.target as HTMLSelectElement).value
        const contributionGraph = document.querySelector(`.contribution-year-${year}`)
        const contributions = document.querySelectorAll('.contributions li')

        contributions.forEach((contribution) => {
            contribution.classList.remove('active')
        })

        contributionGraph?.classList.add('active')
    })
}

const initBookshelf = () => {
    const bookshelfSelect = document.querySelector('.bookshelf-nav select')

    if (bookshelfSelect) {
        bookshelfSelect.addEventListener('change', (event) => {
            const target = (event.target as HTMLSelectElement).value
            window.location.href = target
        })
    }
}

const animateAvatars = () => {
    const tiles = document.querySelectorAll('.fancy-tile')

    tiles.forEach((tile) => {
        tile.addEventListener('mousemove', (event) => {
            const halfW = tile.clientWidth / 2
            const halfH = tile.clientHeight / 2

            var coorX = halfW - (event.pageX - tile.offsetLeft)
            var coorY = halfH - (event.pageY - tile.offsetTop)

            var degX = (coorY / halfH) * 10 + 'deg' // max. degree = 10
            var degY = (coorX / halfW) * -10 + 'deg' // max. degree = 10

            tile.style.transform =
                'perspective( 600px ) translate3d( 0, -2px, 0 ) scale(1.05) rotateX(' + degX + ') rotateY(' + degY + ')'
        })

        tile.addEventListener('mouseout', () => {
            tile.style.transform = ''
        })
    })
}

const dateStringToDateObj = (dateString) => {
    const [dateStr, timeStr] = dateString.split(' ')
    const [year, month, day] = dateStr.split('-')
    const [hours, minutes] = timeStr.split(':')

    return new Date(year, month - 1, day, hours, minutes)
}

const relativeDate = (dateObj) => {
    // Convert to a positive integer
    var time = Math.abs(dateObj.getTime() - new Date().getTime())

    // Define humanTime and units
    var humanTime, units

    // If there are years
    if (time > 1000 * 60 * 60 * 24 * 365) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10)
        units = 'y'
    }

    // If there are months
    else if (time > 1000 * 60 * 60 * 24 * 30) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10)
        units = 'm'
    }

    // If there are weeks
    else if (time > 1000 * 60 * 60 * 24 * 7) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10)
        units = 'w'
    }

    // If there are days
    else if (time > 1000 * 60 * 60 * 24) {
        humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10)
        units = 'd'
    }

    // If there are hours
    else if (time > 1000 * 60 * 60) {
        humanTime = parseInt(time / (1000 * 60 * 60), 10)
        units = 'h'
    }

    // If there are minutes
    else if (time > 1000 * 60) {
        humanTime = parseInt(time / (1000 * 60), 10)
        units = 'm'
    }

    // Otherwise, use seconds
    else {
        humanTime = parseInt(time / 1000, 10)
        units = 's'
    }

    return humanTime + units
}

const relativeCommentDates = () => {
    const comments = document.querySelectorAll('.u-comment time') as NodeListOf<HTMLElement>
    comments.forEach((comment) => {
        const dateObj = dateStringToDateObj(comment.getAttribute('datetime'))
        comment.innerText = relativeDate(dateObj)
    })
}

const fillMastodonFields = async () => {
    const mastodonInstanceCookie = getCookie('mastodon_instance')

    if (mastodonInstanceCookie) {
        const instance = decodeURIComponent(mastodonInstanceCookie).split('+')[1]
        if (instance) {
            const inputField = document.querySelector('#instance') as HTMLInputElement
            if (inputField) {
                inputField.value = instance
            }

            const checkbox = document.querySelector('#rememberInstance') as HTMLInputElement
            if (checkbox) {
                checkbox.checked = true
            }
        }
    }
}

window.onload = () => {
    animateAvatars()
}

enableScrollSpy()
initBookshelf()
initTracking()
initNavAnimation()
relativeCommentDates()
fillMastodonFields()
