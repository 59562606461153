export const quickReply = () => {
    const commentForm = document.querySelector('#kommentform') as HTMLFormElement
    const replyForms = document.querySelectorAll('.quick-reply-form')
    const replyInputs = document.querySelectorAll('.quick-reply')
    const commentTextarea = document.querySelector('#comment') as HTMLTextAreaElement
    const formReplyTo = document.querySelector('#kommentform input[name=replyTo]') as HTMLInputElement
    const formReplyHandle = document.querySelector('#kommentform input[name=replyHandle]') as HTMLInputElement
    const formReplyHandleDisplay = document.querySelector('#kommentform .replyHandleDisplay') as HTMLSpanElement
    const replyLinks = document.querySelectorAll('.kommentReply')

    if (!replyInputs || !commentTextarea || !replyForms || !commentForm) return

    replyInputs.forEach((input) => {
        input.addEventListener('focus', (e) => {
            const target = e.target as HTMLTextAreaElement
            target.rows = 4

            const parentId = target.dataset.id || ''
            const handle = target.dataset.handle || ''

            formReplyTo.value = parentId
            formReplyHandle.value = handle

            formReplyHandleDisplay.innerHTML = `<a href="/@/comment/${parentId}">@${handle}</a>`
        })

        input.addEventListener('blur', (e) => {
            const target = e.target as HTMLTextAreaElement

            if (target.value === '') {
                target.rows = 1
                return
            }

            commentTextarea.value = target.value
        })

        input.addEventListener('change', (e) => {
            const target = e.target as HTMLTextAreaElement
            commentTextarea.value = target.value
        })
    })

    replyForms.forEach((form) => {
        form.classList.toggle('hidden')

        form.addEventListener('submit', (e) => {
            e.preventDefault()
            const target = e.target as HTMLFormElement
            const submitButton = commentForm.querySelector('input[type=submit]') as HTMLInputElement
            submitButton.click()
            target.reset()
            target.rows = 1

            commentForm.scrollIntoView({ behavior: 'smooth' })
        })
    })

    replyLinks.forEach((link) => {
        link.classList.toggle('hidden')
    })
}
