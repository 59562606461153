// https://mauricerenck.test:8890/tools/api/weather

export const getCurrentWeatherCondition = () => {
    const itemStr = localStorage.getItem('weatherCache')
    // if the item doesn't exist, return null
    if (!itemStr) {
        fetchWeatherData()
        return
    }

    const conditionCache = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > conditionCache.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem('weatherCache')
        fetchWeatherData()
        return
    }

    renderWidget(conditionCache)
}

const fetchWeatherData = () => {
    fetch('/tools/api/weather')
        .then((response) => response.json())
        .then((data) => {
            if (!data.conditionId) {
                return
            }

            const now = new Date()
            const conditionCache = {
                conditionId: data.conditionId,
                sunrise: data.sunrise,
                sunset: data.sunset,
                expiry: now.getTime() + 2 * 60 * 60 * 1000,
            }
            localStorage.setItem('weatherCache', JSON.stringify(conditionCache))

            renderWidget(data)
        })
}

const renderWidget = (data) => {
    const timestamp = new Date().getTime() / 1000
    const isDay = timestamp <= data.sunset && timestamp > data.sunrise
    const weatherWidget = document.querySelector('.the-tree .weather')
    const tree = document.querySelector('.the-tree')
    const goodConditions = [800, 801, 803, 804]

    if (goodConditions.indexOf(data.conditionId) === -1) {
        document.querySelector('#swing').classList.add('empty')
        const parts = tree.querySelectorAll('.hideWhenBadCondition')

        parts.forEach((part) => {
            part.remove()
        })
    }

    if (weatherWidget) {
        weatherWidget.innerHTML = `<img src="/assets/img/weather/${isDay ? 'day' : 'night'}-${data.conditionId}.svg">`
    }
}
