export const initNavigation = () => {
    const nav = document.querySelector('#navigation')
    const toggle = document.querySelector('#navigation button')
    const menu = document.querySelector('#navigation ol')

    if (!nav || !toggle || !menu) {
        return
    }

    toggle.addEventListener('click', () => {
        menu.classList.toggle('mobile-visible')
        // menu.classList.toggle('display-block')
    })
}

export const gotoActiveNavEntry = () => {
    const navigation = document.querySelector('#navigation')
    const navEntryActive = navigation?.querySelector('li a.active')
    const animation = navigation?.querySelector('.animation')

    if (animation && navEntryActive) {
        const rect = navEntryActive.getBoundingClientRect()
        const left = rect.left - 30
        const top = rect.top + window.scrollY
        const width = rect.width + 20

        animation.style.left = `${left}px`
        animation.style.top = `${top}px`
        animation.style.width = `${width}px`
    }
}

export const initNavAnimation = () => {
    const navigation = document.querySelector('#navigation')
    const navEntries = navigation?.querySelectorAll('li a')
    const animation = navigation?.querySelector('.animation')

    if (animation) {
        navEntries?.forEach((entry) => {
            entry.addEventListener('mouseenter', (event) => {
                const target = event.target as HTMLElement
                const rect = target.getBoundingClientRect()
                const left = rect.left - 30
                const top = rect.top + window.scrollY
                const width = rect.width + 20

                animation.style.left = `${left}px`
                animation.style.top = `${top}px`
                animation.style.width = `${width}px`
            })

            navigation?.addEventListener('mouseleave', () => {
                gotoActiveNavEntry()
            })
        })

        gotoActiveNavEntry()
    }
}
