export const copyToClipboard = () => {
    const items: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a.copyToClipboard')

    items.forEach((item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault()

            const text = item.dataset.text

            if (!text) {
                return
            }

            navigator.clipboard.writeText(text)

            UIkit.notification({
                message: '<span uk-icon="icon:check"></span> Kopiert/Copied',
                status: 'success',
                pos: 'top-right',
                timeout: 5000,
            })
        })
    })
}
