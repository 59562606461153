export type ScrollSpyElement = {
    element: any
    className: string
    toggleClass: boolean
}

const scrollSpyEntries: ScrollSpyElement[] = []

export const addScrollSpyEntry = (element: any | null, toggleClass: boolean, className?: string | null) => {
    if (!element) {
        return
    }

    scrollSpyEntries.push({
        element,
        className: className || 'visible',
        toggleClass: toggleClass || false,
    })
}

export const enableScrollSpy = () => {
    const observer = new IntersectionObserver(scrollSpyCallback, {
        rootMargin: '0px',
        threshold: 0.5,
    })

    const scrollSpyElements = document.querySelectorAll('.scrollspy')

    scrollSpyElements.forEach((element) => {
        const className = element.getAttribute('data-scrollspy-class')
        const toggleClass = element.getAttribute('data-scrollspy-toggleClass') === 'true'

        addScrollSpyEntry(element, toggleClass, className)
        observer.observe(element)
    })
}

const scrollSpyCallback = (entries) => {
    entries.map((entry) => {
        if (!scrollSpyEntries || !entry.target) {
            return
        }

        const targetElement = scrollSpyEntries.find((scrollSpyEntry) => {
            return scrollSpyEntry.element === entry.target
        })

        if (!targetElement) {
            return
        }

        if (entry.isIntersecting) {
            entry.target.classList.add(targetElement.className)
        } else {
            if (targetElement.toggleClass) {
                entry.target.classList.remove(targetElement.className)
            }
        }
    })
}
